import colors from './colors';
import fonts from './fonts';
import layout from './layout';

const theme = {
  colors,
  fonts,
  layout,
};

export default theme;
